import BlogFeatureArticleGalleryUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/BlogFeatureArticleGallery/BlogFeatureArticleGallery'
import React, { useContext } from 'react'
import AnalyticsContext from 'context/AnalyticsContext'
import { Nullable } from '@interflora/ui-components/build/common/props'

type Props = UIProps
const BlogFeatureArticleGallery = (props) => {
  const { items } = props as Nullable<Props>

  const analytics = useContext(AnalyticsContext)

  const blogArticleGalleryUrlAnalytics = (linkText: string) => {
    analytics.blogArticleGalleryAnalytics(linkText)
  }

  return <BlogFeatureArticleGalleryUI items={items} blogArticleGalleryUrlAnalytics={blogArticleGalleryUrlAnalytics} />
}

export default BlogFeatureArticleGallery
