import React from 'react'
import { useHits } from 'react-instantsearch'
import { default as BlogLatestArticleGalleryUI } from '@interflora/ui-components/build/components/BlogLatestArticleGallery/BlogLatestArticleGallery'
import { AlgoliaBlogLatestArticles, LinkProps } from '@interflora/ui-components/build/common/props'
import { algoliaBlogArticleMapping } from '@interflora/ui-components/build/utils/common'

type Props = {
  title: string
  link: LinkProps
}

const ListingWrapper = ({ title, link }: Props) => {
  const hits = useHits<AlgoliaBlogLatestArticles>()
  const latestArticlesList = algoliaBlogArticleMapping(hits.items)
  return <BlogLatestArticleGalleryUI title={title} link={link} latestArticlesList={latestArticlesList} />
}

export default ListingWrapper
