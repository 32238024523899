import React, { useContext } from 'react'
import { LinkProps } from '@interflora/ui-components/build/common/props'
import AlgoliaWrapper from 'components/AlgoliaWrapper/AlgoliaWrapper'
import { Configure } from 'react-instantsearch'
import ListingWrapper from './ListingWrapper/ListingWrapper'
import { SiteContext } from '@interflora/ui-components/build/utils/common'

type Props = {
  title: string
  link: LinkProps
}

const BlogLatestArticleGallery = (props: Props) => {
  const { title, link } = props
  const { shorthand } = useContext(SiteContext)
  return (
    <AlgoliaWrapper indexName={`blog_article_${shorthand}_published_date_desc`}>
      <Configure hitsPerPage={9} distinct={true} />
      <ListingWrapper {...props} title={title} link={link} />
    </AlgoliaWrapper>
  )
}

export default BlogLatestArticleGallery
